<template>
  <section id="home">
    <header>
      <img
        v-show="$root.rwd > 1"
        src="../assets/home/mobile-banner.png"
        alt="Banner"
        width="100%"
      />
      <div class="col" align-x="center" gutter="30">
        <template v-if="$root.rwd < 2">
          <h1 style="margin-bottom: 0">線上闖關活動</h1>
          <h3 style="margin-top: 0">2022/9/30～2022/10/31</h3>
        </template>
        <p class="note">
          2022年心理健康月線上活動開跑了！即日起加入臺中市社區心理衛生中心官方LINE帳號，參與「從心定義你的宇宙」， 即有機會抽Switch OLED、Apple Watch S7、AirPods Pro等多項好禮。詳細辦法可參閱下方活動資訊
        </p>
        <template v-if="$root.rwd > 1">
          <a :href="lineOAlink" target="_blank">
            <img src="../assets/home/2.png" width="400" />
          </a>
          <a href="https://wenk.io/i08tebrA" target="_blank">
            <img src="../assets/home/mobile-3.png" width="100%" />
          </a>
        </template>
        <img src="../assets/home/1.png" width="100%" />
        <p class="note">
          <strong>活動名稱：2022 心理健康月 - 從心定義你的宇宙【闖關活動】</strong>
          <br />活動時間：2022/9/30～2022/10/31
          <br />參加資格：加入臺中市社區心理衛生中心官方LINE帳號，即可參與闖關活動
          <br />
          <br />好康第一重：完成任一關卡，點擊「登錄抽獎」，經系統審查確認有完成關卡後，即有機會獲得 5 點 LINE POINTS，限量2,000份送完為止  (同一帳號僅能參加一次)。
          <br />
          <br />好康第二重：分享「臺中市社區心理衛生中心官方LINE帳號」給2位好友，該2位好友經系統確認已加入官方帳號，即贈送5點LINE POINTS，限量3,000份送完為止 (同一帳號僅能參加一次)。
        </p>
        <br />
        <p class="note">
          <strong>活動名稱：2022 心理健康月 - 從心定義你的宇宙【投票抽獎活動】</strong>
          <br />活動時間：2022/10/31 ~ 2022/11/21中午12時
          <br />參加資格：
          <br />
          <br />加入臺中市社區心理衛生中心官方LINE帳號，參與「網紅紓壓方式」投票活動，填寫心理健康問卷並登錄抽獎資料，即可參加抽獎 (一個 ID 僅能參加一次)，所有兌獎資格須經主辦單位檢核並以主辦單位審核為準
        </p>
        <template v-if="$root.rwd < 2">
          <a :href="lineOAlink" target="_blank">
            <img src="../assets/home/2.png" width="400" />
          </a>
          <div class="row" align-y="center" gutter="20">
            <img :src="qrcodeSRC" alt="QR code" />
          </div>
        </template>
      </div>
    </header>
    <section
      class="col"
      align-x="center"
      style="max-width: 1200px; margin: auto; padding: 5%; padding-top: 0"
    >
      <template v-if="$root.rwd < 2">
        <a href="https://wenk.io/i08tebrA" target="_blank">
          <img src="../assets/home/3.png" width="100%" />
        </a>
        <br />
      </template>
      <div class="card col">
        <img
          v-if="$root.rwd > 1"
          src="../assets/home/mobile-4.png"
          width="100%"
        />
        <img v-else src="../assets/home/4.png" width="100%" />
        <br />
        <div
          class="row-grid prize"
          gutter-x="20"
          gutter-y="20"
          style="width: 100%; max-width: 800px; margin: auto; font-weight: 500;;"
        >
          <template v-for="item in list">
            <div
              class="grid-item col rwd-s-4 rwd-xs-6"
              align-x="center"
              gutter="10"
              :key="item.no"
            >
              <span>{{ item.no }}</span>
              <img :src="item.thumb" :alt="item.name" />
              <span>{{ item.name }}</span>
            </div>
          </template>
        </div>
        <br />
        <p v-show="$root.rwd < 2">
          <b>得獎者須知：</b>
          <br />得獎者以台中市衛生局「健康小衛星」臉書粉絲團公告為主，並同步以E-mail通知，主辦單位將依抽獎資料進行寄送，恕無法變更獎品收件資訊。如有問題請撥打服務電話 02 6636 6652 #506 / 電子郵件 2021mh.service@gmail.com，臺中市心理健康月活動專案小組。
        </p>
      </div>

      <div style="padding: 5%">
        <p class="note">
          <b>注意事項：</b> <br />
          ❶ 獎項詳細內容與規格皆以實物為準，得獎人不得要求獎項更換或轉讓。<br />
          ❷ 臺中市政府衛生局保有隨時修改及終止本活動之權利，如有任何變更內容或詳細注意事項將公布於台中市衛生局-健康小衛星臉書粉絲團，恕不另行通知。
          <br />
          ❸ 參加者必須依照活動網頁上的指示，完成指定動作並填寫基本資料者，方有抽獎資格。
          <br />
          ❹ 本活動抽獎規則已詳細載明於活動網頁中，參加者於參加本活動時，即同意接受所有活動規則之規範。如有違反本活動規則，主辦單位得以取消參加抽獎資格並依法處理。
          <br />
          ❺ 參加者保證所有填寫或提出的資料並非冒用或盜用第三人之個資，如有不實或不正確之情事或第三方提出任何法律訴訟，得由參加者本人全權負責，概與主辦單位無關，同時主辦單位有權利取消該參加者抽獎資格。
          <br />
          <br /><b>活動須知：</b> <br />
          ❶ 獎品以實物為準，如遇產品缺貨或其他不可抗力之事由，主辦單位得保留更換等值獎品之權利，不另行告知。
          <br />❷
          本活動若因故無法進行時，主辦單位將於臺中市社區心理衛生中心官方LINE帳號、台中市衛生局-健康小衛星臉書粉絲團上公告，並有權決定修改、暫停或取消本活動。
          <br />❸
          本活動僅限於設籍於台灣、金門、澎湖、馬祖地區者參加，配送地址以中獎人所填資料之地址為準，贈品無法寄送海外，主辦單位亦不負贈品無法送達之責任。
          <br />❹
          民眾參與本活動之同時，即視為同意接受本活動之規範，如有違反、或有任何以詐騙方式或其他足以破壞本活動之不法行徑，或經主辦單位查獲以任何方式，導致系統異常者，一律視為無效資料。主辦單位保留隨時修正、暫停或終止本活動之權利，如有變動將公告於臺中市社區心理衛生中心官方LINE帳號、台中市衛生局-健康小衛星臉書粉絲團。
          <br />❺
          若中獎人因資格不符或同意放棄中獎資格者，主辦單位不另行通知、公告。
          <br />❻
          參加者同意填寫或提出之資料均為真實且正確，未冒用或盜用任何第三人之資料。如有致損害於主辦單位或其他第三人，參加者應負一切相關責任。如未提供正確且完整之個人基本資料或聯絡資料，導致主、協辦單位於活動中獎時無法通知或寄送獎品者，視同放棄中獎資格。主辦單位不另行通知且不負任何責任。
          <br />❼
          為求活動之公平及公正，參加者若以惡意之電腦程式或其他明顯違反活動公平性之方式，意圖混淆或影響活動結果者，一經主辦單位察覺得立即取消參加者之得獎資格，並得追回獎品及活動執行單位將保留法律追訴權力。
          <br />
          <br />
          活動網址：https://taichung-mental-landing.wenk-media.com/
        </p>
      </div>
      <a v-show="$root.rwd > 1" :href="lineOAlink" target="_blank">
        <img src="../assets/home/2.png" width="400" />
      </a>
    </section>
  </section>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          no: '1獎',
          thumb: require('../assets/home/6.png'),
          name: 'Apple Watch S7 x 1',
        },
        {
          no: '2獎',
          thumb: require('../assets/home/7.png'),
          name: 'Switch OLED X 1',
        },
        {
          no: '3獎',
          thumb: require('../assets/home/8.png'),
          name: 'AirPods Pro x 1',
        },
        {
          no: '4獎',
          thumb: require('../assets/home/9.png'),
          name: '500元禮卷 x 20',
        },
        {
          no: '5獎',
          thumb: require('../assets/home/10.png'),
          name: '100元禮卷 x 50',
        },
      ],
    };
  },
  computed: {
    lineOAlink() {
      return process.env.VUE_APP_LINE_OA;
    },
    qrcodeSRC() {
      return require(`../assets/home/qr.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
  line-height: 1.5;
  background-color: #D5F3FA;
  background-image: url('../assets/home/background.png');
  background-size: 500px;
  background-position: center;
  @media screen and (min-width: 769px) {
    background-size: 1440px;
  }
}
header {
  @media screen and (min-width: 769px) {
    background-image: url('../assets/home/banner.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    padding: 5%;
  }
  max-width: 1440px;
  margin: auto;

  h1,
  h3 {
    text-align: center;
    color: #165492;
  }

  .col {
    padding: 7%;
    @media screen and (min-width: 769px) {
      padding-top: 20%;
      width: 65%;
      margin: auto;
    }
  }
}

.prize{
  display: flex;
  justify-content: center;
}
.card {
  background: #fff;
  border-radius: 20px;
  padding: 5%;
  padding-bottom: 10px;
  border: 1px solid #bb9938;
}
.grid-item {
  text-align: center;

  img {
    width: 100%;
    max-width: 160px;
  }
}
.note {
  background: #D5F3FA;
  padding: 1em;
}
</style>